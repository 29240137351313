<template>
  <b-card
    class="produceordersource-edit-wrapper"
  >
    <!-- form -->
    <b-form id="produceordersourceForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购订单ID"
            label-for="produce_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="produce_id"
              size="sm"
              v-model="produceordersource.produce_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产单编号"
            label-for="produce_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="produce_no"
              size="sm"
              v-model="produceordersource.produce_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否主原料"
            label-for="is_main"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_main"
              size="sm"
              v-model="produceordersource.is_main"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="produceordersource.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存编号"
            label-for="stock_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_no"
              size="sm"
              v-model="produceordersource.stock_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存所在仓库ID"
            label-for="warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_id"
              size="sm"
              v-model="produceordersource.warehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存所在仓库名称"
            label-for="warehouse_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_name"
              size="sm"
              v-model="produceordersource.warehouse_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="锁库ID"
            label-for="stocklock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stocklock_id"
              size="sm"
              v-model="produceordersource.stocklock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单产品明细ID"
            label-for="purchase_orderitem_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="purchase_orderitem_id"
              size="sm"
              v-model="produceordersource.purchase_orderitem_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="produceordersource.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="产品批次"
            label-for="expiry_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expiry_date"
              size="sm"
              v-model="produceordersource.expiry_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产日期"
            label-for="production_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="production_date"
              size="sm"
              v-model="produceordersource.production_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态"
            label-for="status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status"
              size="sm"
              v-model="produceordersource.status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="produceordersource.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否赠品"
            label-for="is_gift"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_gift"
              size="sm"
              v-model="produceordersource.is_gift"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品规格"
            label-for="specification"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="specification"
              size="sm"
              v-model="produceordersource.specification"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="基本单位 product_unit"
            label-for="product_unit"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_unit"
              size="sm"
              v-model="produceordersource.product_unit"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="单箱标准数量"
            label-for="box_quantity"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="box_quantity"
              size="sm"
              v-model="produceordersource.box_quantity"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="成本价  商品入库时的含税单价"
            label-for="cost_price"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="cost_price"
              size="sm"
              v-model="produceordersource.cost_price"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="预计使用数量"
            label-for="purchase_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="purchase_qty"
              size="sm"
              v-model="produceordersource.purchase_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际使用数量"
            label-for="true_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="true_qty"
              size="sm"
              v-model="produceordersource.true_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="produceordersource.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="produceordersource.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="produceordersource.modify_time"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import produceordersourceStore from './produceordersourceStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      produceordersource: ref({}),
    }
  },

  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('produceordersource')) store.registerModule('produceordersource', produceordersourceStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('produceordersource')) store.unregisterModule('produceordersource')
    })

    const edit = function() {
      store.dispatch('produceordersource/edit', {id: this.id}).then(res => {
        this.produceordersource = res.data.data
      })
    }

    const view = function() {
      store.dispatch('produceordersource/view', {id: this.id}).then(res => {
        this.produceordersource = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('produceordersource/save', this.produceordersource).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
